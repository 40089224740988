import React from "react";
import "./App.css";

export default function PageContact(props) {
  return (
    <>
      {props.lang == "francais" && (
        <h5
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Le système Traceur peut être mis en place très facilement par les
          industriels
        </h5>
      )}
      {props.lang == "english" && (
        <h5
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          The Traceur system can be implemented very easily by manufacturers
        </h5>
      )}

      {/* <div className="small-image-container"> */}
      {/* <img className="small-image-container"
          src="fruits-and-vegetables-732x549.jpg"
          alt="fruits"
          height="100"
          width="20%"
        /> */}
      {/* </div> */}
      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          {/* <br /> */}
          <br />
          L'utilisation du site Web dédié aux industriels leur permettant de
          déclarer leurs lots requiert moins de deux heures de formation. Le
          qr-code généré, adjoint à l'etiquette sur les produits, peut être lu
          aussitôt par l'application mobile "Cherche Youki!".
          <br />
          <br />
          <b>
            Prenez contact en nous écrivant à l'adresse:{" "}
            <i>contact@traceur-de-lots.eu</i>
          </b>
        </p>
      )}
      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          {/* <br /> */}
          <br />
          Using the website dedicated to manufacturers allowing them to declare
          their batches requires less than two hours of training. The qr-code
          generated, attached to the label on the products, can be read
          immediately by the mobile application "Cherche Youki!".
          <br />
          <br />
          <b>
            Contact us by writing to the address:{" "}
            <i>contact@traceur-de-lots.eu</i>
          </b>
        </p>
      )}

      <img src="equipe.png" alt="equipe" width="50%" />
      <br />
      <br />
    </>
  );
}
