import React from "react";
import "./App.css";

export default function PageAppliMobile(props) {
  return (
    <>
      {props.lang == "francais" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          L'appli mobile "Cherche Youki!" permet aux consommateurs d'accéder à
          la chaîne de traçabilité d'un produit
        </h2>
      )}
      {props.lang == "english" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          The mobile app "Cherche Youki!" allows consumers to access the
          traceability chain of a product
        </h2>
      )}
      <br />
      {/* <div className="small-image-container"> */}
      <img
        className="small-image-container"
        src="mobile-produit.png"
        alt="application mobile de traçabilité produit"
        // height="50%"
        width="20%"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="small-image-container"
        src="mobile-ingredients.png"
        alt="application mobile de traçabilité ingrédient"
        // height="50%"
        width="20%"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <img
        className="small-image-container"
        src="mobile-pate-feuilletee.png"
        alt="application mobile de traçabilité ingrédient d'ingrédient"
        // height="50%"
        width="20%"
      />
      <br />
      <br />
      {/* </div> */}
      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          {/* <br /> */}
          <br />
          L'application mobile "Cherche Youki!" permet au consommateur de
          scanner un qr-code figurant sur le produit pour avoir accès à la
          chaîne de fabrication. Des informations concernant le produit lui sont
          fournies ainsi que la liste des ingrédients. Le consommateur peut
          cliquer sur un ingrédient pour avoir le même type d'information le
          concernant et ainsi de suite, récursivement, ayant accès ainsi à toute
          la chaîne de fabrication.
          <br />
          Le consommateur voit la note et le classement du lot calculés par
          Traceur.
          <br />
          <br />
          L'application mobile "Cherche Youki!" est disponible sur Android et
          Iphone.
          <br />
        </p>
      )}
      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          {/* <br /> */}
          <br />
          The mobile application "Cherche Youki!" allows the consumer to scan a
          QR code on the product to access the manufacturing chain. Information
          about the product is provided as well as the list of ingredients. The
          consumer can click on an ingredient to have the same type of
          information about it and so on, recursively, thus having access to the
          entire manufacturing chain. 
          <br />
          The consumer sees the score and the ranking of the batch calculated by
          Traceur.
          <br />
          <br />
          The mobile application "Cherche Youki!" is available on Android and
          iPhone.
          <br />
        </p>
      )}
      <br />
      <br />
      {/* <img src="google-app-store.png" alt="google app store" height="100" />
      <br /> */}
      <br />
    </>
  );
}
