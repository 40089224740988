import React from "react";
import "./App.css";

export default function PageSiteFournisseurs(props) {
  return (
    <>
      {props.lang == "francais" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Un site web dédié permet aux fournisseurs de déclarer un nouveau lot
          de fabrication et d'indiquer ses lots ingrédients.
          <br />
          Le système Traceur reconstitue toute la chaîne de traçabilité
        </h2>
      )}

      {props.lang == "english" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          A dedicated website allows suppliers to declare a new manufacturing
          batch and indicate its ingredient batches.
          <br />
          The Traceur system reconstructs the entire traceability chain
        </h2>
      )}

      {/* <div className="small-image-container"> */}
      <br />
      <img
        className="small-image-container"
        src="graphe.png"
        alt="chaîne de traçabilité"
        // height="40%"
        width="60%"
        // width="20%"
      />
      <br />
      {/* </div> */}

      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          <br />
          <br />
          La notion de lot est définie ainsi par la norme ISO 22005 : "ensemble
          d'unités d'un produit qui ont été fabriquées et/ou traitées ou
          emballées dans les mêmes conditions".
          <br />
          Un site Web spécifique est dédié aux industriels, qui leur permet
          d'ouvrir un compte protégé sur la blockchain et de déclarer chaque lot
          de production. Un lot peut correspondre à des millers d'articles.
          <br />
          <br />
          L'industriel renseigne des informations concernant le lot de
          production ainsi que les lots ingrédients qui ont été utilisés.
          Traceur retrouve alors les informations qui ont été entrées par les
          founisseurs de ces lots ingrédients et reconstitue de proche en proche
          toute la chaîne de fabrication.
          <br />
          <br />
          Une note sur 100 points est allouée par le système Traceur selon le
          niveau d'informations données concernant le produit et les trois
          niveaux inférieurs d'ingrédients. Le lot est classé parmi tous les
          lots de la base par quintile (5 étoiles signifie dans les 20%
          meilleurs)
          <br />
        </p>
      )}

      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          <br />
          <br />
          The notion of batch is defined as follows by the ISO 22005 standard:
          "set of units of a product that have been manufactured and/or
          processed or packaged under the same conditions".
          <br />
          A specific website is dedicated to manufacturers, which allows them to
          open a protected account on the blockchain and to declare each
          production batch. A batch can correspond to thousands of items.
          <br />
          <br />
          The manufacturer enters information concerning the production batch as
          well as the batches of ingredients that were used. Traceur then finds
          the information that was entered by the suppliers of these batches of
          ingredients and reconstructs the entire manufacturing chain step by
          step.
          <br />
          <br />
          A score out of 100 points is allocated by the Traceur system according
          to the level of information given concerning the product and the three
          lower levels of ingredients. The lot is ranked among all lots in the
          base by quintile (5 stars means in the top 20%)
          <br />
        </p>
      )}

      <br />
      <br />

      {/* <img src="google-app-store.png" alt="google app store" height="100" />
      <br /> */}
      <br />
    </>
  );
}
