import React from "react";
import "./App.css";

export default function PageFonctionnement(props) {
  return (
    <>
      {props.lang == "francais" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Le système Traceur = une blockchain + un site web pour les
          fournisseurs + une appli mobile pour les consommateurs
        </h2>
      )}
      {props.lang == "english" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          The Traceur system = a blockchain + a website for suppliers <br /> + a
          mobile app for consumers
        </h2>
      )}

      {/* <div className="small-image-container"> */}
      <img
        className="small-image-container"
        src="schema.jpg"
        alt="architecture du système Traceur"
        // height="30%"
        width="50%"
      />
      {/* </div> */}
      <br />

      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> 
        <br /> */}
          <br />
          Traceur permet aux consommateurs d'accèder à des informations
          concernant un produit, ses ingrédients et de remonter la chaîne de
          fabrication. Pour cela, les industriels font référence aux ingrédients
          qu'ils approvisionnent auprès de leurs fournisseurs. La confiance que
          tous les acteurs ont vis-à-vis des informations figurant dans la base
          doit être totale.
          <br />
          <br />
          C'est pour cela que la base de données utilise la technologie
          Blockchain, qui garantit par construction la pérennité et l'intégrité
          des données. Les données sont introduites conjointement dans la base
          par différents acteurs (les "Mineurs") si bien qu'aucune entrée
          illicite n'est possible. De plus, les opérations exécutées à chaque
          interaction avec la base sont publiques et peuvent être vérifiées par
          tous.
          <br />
          <br />
          Les informations concernant chaque lot de production et la liste de
          ses ingrédients sont entrées en quelques minutes par l'industriel.
          Pour cela l'industriel accéde à un site Web dédié en utilisant son
          propre compte protégé. Sur le point de vente ou après l'achat, le
          consommateur peut scanner le qr-code figurant sur l'étiquette du
          produit et avoir accès aux informations concernant le lot de
          production auquel le produit appartient. Il accède également aux
          informations concernant les lots des ingrédients puis remonte, autant
          que c'est possible, la chaîne de fabrication.
        </p>
      )}

      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> 
        <br /> */}
          <br />
          Traceur allows consumers to access information about a product, its
          ingredients and to trace the manufacturing chain. To do this,
          manufacturers give the reference of the ingredients they purchase from their
          suppliers. The trust that all the stakeholders have in the information
          in the database must be total.
          <br />
          <br />
          This is why the database uses Blockchain technology, which guarantees
          by construction the durability and integrity of the data. The data is
          entered jointly into the database by different stakeholders (the
          "Miners") so that no illicit entry is possible. In addition, the
          operations carried out at each interaction with the database are
          public and can be verified by everyone.
          <br />
          <br />
          The information concerning each production batch and the list of its
          ingredients is entered in a few minutes by the manufacturer. To do
          this, the manufacturer accesses a dedicated website using his own
          protected account. At the point of sale or after purchase, the
          consumer can scan the QR code on the product label and have access to
          information about the production batch to which the product belongs.
          He also accesses information about the batches of the ingredients and
          then goes back, as far as possible, the manufacturing chain.
        </p>
      )}

      {/* <img src="google-app-store.png" alt="google app store" height="100" />
      <br /> */}
    </>
  );
}
