import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Toast from "react-bootstrap/Toast";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
//import FormCreerLot from "./FormCreerLot";

import PageAccueil from "./PageAccueil";
import PageFonctionnement from "./PageFonctionnement";
import PageSiteFournisseurs from "./PageSiteFournisseurs";
import PageAppliMobile from "./PageAppliMobile";
import PageBlockchain from "./PageBlockchain";
import PageFondateurs from "./PageFondateurs";
import PageContact from "./PageContact";

export default function Navigation() {
  const [choixAccueil, setChoixAccueil] = useState(true);
  const [choixFonctionnement, setChoixFonctionnement] = useState(false);
  const [choixSiteFournisseurs, setChoixSiteFournisseurs] = useState(false);
  const [choixAppliMobile, setChoixAppliMobile] = useState(false);
  const [choixBlockchain, setChoixBlockchain] = useState(false);
  const [choixFondateurs, setChoixFondateurs] = useState(false);
  const [choixContact, setChoixContact] = useState(false);
  const [langage, setLangage] = useState("francais");
  const [systemTraceur, setSystemTraceur] = useState("Le systeme Traceur");

  // useEffect(() => {
  //   setLe_langage(langage);
  //   console.log(langage);
  // }, [langage]);

  function changerLangageFrancais() {
    setLangage("francais");
    setSystemTraceur("Le systeme Traceur");
  }

  function changerLangageEnglish() {
    setLangage("english");
    setSystemTraceur("The Traceur System");
  }

  // routage à la main, on cache tout et on montre le composant choisi
  function toutCacher() {
    setChoixAccueil(false);
    setChoixFonctionnement(false);
    setChoixSiteFournisseurs(false);
    setChoixAppliMobile(false);
    setChoixBlockchain(false);
    setChoixFondateurs(false);
    setChoixContact(false);
  }

  function handlePageAccueil() {
    toutCacher();
    setChoixAccueil(true);
  }

  function handlePageFonctionnement() {
    toutCacher();
    setChoixFonctionnement(true);
  }

  function handlePageSiteFournisseurs() {
    toutCacher();
    setChoixSiteFournisseurs(true);
  }

  function handlePageAppliMobile() {
    toutCacher();
    setChoixAppliMobile(true);
  }

  function handlePageBlockchain() {
    toutCacher();
    setChoixBlockchain(true);
  }

  function handlePageFondateurs() {
    toutCacher();
    setChoixFondateurs(true);
  }

  function handlePageContact() {
    toutCacher();
    setChoixContact(true);
  }

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Container>
          <Navbar.Brand
          // href="#home"
          >
            <img
              alt="dog"
              src="dogTraceur.png"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            &nbsp;&nbsp;TRACEUR
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {/* <Nav className="me-auto"> */}
            <Nav className="container-fluid">
              <Nav.Link active={choixAccueil} onClick={handlePageAccueil}>
                {langage == "francais" && "Accueil"}
                {langage == "english" && "Welcome"}
              </Nav.Link>

              <NavDropdown title={systemTraceur} id="basic-nav-dropdown">
                <NavDropdown.Item
                  // href="#action/3.1"
                  onClick={handlePageFonctionnement}
                >
                  {langage == "francais" && "Le fonctionnement"}
                  {langage == "english" && "How it works"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  // href="#action/3.1"
                  onClick={handlePageSiteFournisseurs}
                >
                  {langage == "francais" && "Le site Fournisseurs"}
                  {langage == "english" && "The suppliers web site"}
                </NavDropdown.Item>
                <NavDropdown.Item
                  // href="#action/3.2"
                  onClick={handlePageAppliMobile}
                >
                  {langage == "francais" &&
                    "L'application mobile Cherche Youki!"}
                  {langage == "english" && "The mobile app Cherche Youki!"}
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated Link
                </NavDropdown.Item> */}
              </NavDropdown>

              <Nav.Link onClick={handlePageBlockchain}>
                {langage == "francais" && "La Blockchain"}
                {langage == "english" && "The Blockchain"}
              </Nav.Link>

              <Nav.Link onClick={handlePageFondateurs}>
                {langage == "francais" && "Les Fondateurs"}
                {langage == "english" && "The Founders"}
              </Nav.Link>

              <Nav.Link onClick={handlePageContact}>
                {langage == "francais" && "Contact"}
                {langage == "english" && "Contact"}
              </Nav.Link>

              <Navbar.Collapse className="justify-content-end">
                <Nav.Link onClick={changerLangageFrancais}>FR</Nav.Link>
                <Nav.Link onClick={changerLangageEnglish}>EN</Nav.Link>
              </Navbar.Collapse>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* plusieurs containers pour choisir des dimensions différentes selon les cas */}

      <br />
      <br />
      <br />

      <Container>
        <Row>
          <Col></Col>
          <Col xs={10}>{choixAccueil && <PageAccueil lang={langage}/>}</Col>
          <Col></Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col></Col>
          <Col xs={10}>{choixFonctionnement && <PageFonctionnement lang={langage} />}</Col>
          <Col></Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col></Col>
          <Col xs={10}>{choixSiteFournisseurs && <PageSiteFournisseurs lang={langage} />}</Col>
          <Col></Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col></Col>
          <Col xs={10}>{choixAppliMobile && <PageAppliMobile lang={langage}/>}</Col>
          <Col></Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col></Col>
          <Col xs={10}>{choixBlockchain && <PageBlockchain lang={langage}/>}</Col>
          <Col></Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col></Col>
          <Col xs={10}>{choixFondateurs && <PageFondateurs lang={langage}/>}</Col>
          <Col></Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col></Col>
          <Col xs={10}>{choixContact && <PageContact lang={langage}/>}</Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
}
