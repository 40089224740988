import React from "react";
import "./App.css";

export default function PageFondateurs(props) {
  return (
    <>
      {props.lang == "francais" && (
        <h5
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          La société TRACEUR SAS a été fondée par Djamel et Sadek Chekroun,{" "}
          <br />
          deux professionnels expérimentés de l'informatique et du Web.
        </h5>
      )}
      {props.lang == "english" && (
        <h5
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          The company TRACEUR SAS was founded by Djamel and Sadek Chekroun,{" "}
          <br />
          two experienced IT and Web professionals.
        </h5>
      )}

      {/* <div className="small-image-container"> */}
      {/* <img className="small-image-container"
          src="fruits-and-vegetables-732x549.jpg"
          alt="fruits"
          height="100"
          width="20%"
        /> */}
      {/* </div> */}
      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          {/* <br /> */}
          <br />
          <u>
            <b>Djamel Chekroun</b>
          </u>{" "}
          est diplômé de l’Ecole Polytechnique, de l’Ecole Nationale Supérieure
          des Télécommunications et de l’Université de Stanford. Après ses
          études il a rejoint le laboratoire de traitement de la parole de
          Thomson-CSF, a fondé une startup dans le domaine des systèmes experts
          puis a participé à la conception de satellites au sein de Matra-Espace
          et au déploiement des premiers réseaux mobiles GSM. Il a été ensuite
          Directeur Commercial de la Division Télécoms de Sagem puis Directeur
          Général de l’équipementier CS Télécoms. Il a passé plusieurs années
          dans le financement de l’innovation au sein de la société de gestion
          ACG puis s’est consacré à l’informatique en conduisant le
          développement du système TRACEUR.
          <br />
          <br />
          <u>
            <b>Sadek Chekroun</b>
          </u>{" "}
          est diplômé de l’École Supérieure d’Ingénieurs en Génie Électrique
          de Rouen. Il a debuté sa carrière chez des éditeurs de logiciels pour
          grandes entreprises (Cincom, Sterling Software, Computer Associates) à
          des postes à responsabilités technico-commerciales puis Marketing. Il
          a rejoint ensuite la division logicielle d’IBM France puis le siège
          EMEA en tant que directeur du Marketing. Parmi les pionniers de
          l’Internet, il a cofondé Skyworld l’un des tout premiers
          fournisseurs d’accès en France revendu à CEGETEL avant de rejoindre
          Kelkoo comme Directeur Général puis VP e-Commerce Europe à l'occasion
          du rachat par Yahoo!. II a ensuite occupé des postes de direction
          générale de startups telles que Eyeka et Wikio/Teads et collaboré avec
          un Family office en Asie.
          <br />
        </p>
      )}

      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          {/* <br /> */}
          <br />
          <u>
            <b>Djamel Chekroun</b>
          </u>{" "}
          is a graduate of the Ecole Polytechnique, the Ecole Nationale
          Supérieure des Télécommunications and Stanford University. After his
          studies, he joined the speech processing laboratory of Thomson-CSF,
          founded a startup in the field of expert systems and then participated
          in the design of satellites within Matra-Espace and the deployment of
          the first GSM mobile networks. He was then Sales Director of the
          Telecoms Division of Sagem and then General Manager of the equipment
          manufacturer CS Télécoms. He spent several years in innovation
          financing within the management company ACG and then devoted himself
          to IT by leading the development of the TRACEUR system.
          <br />
          <br />
          <u>
            <b>Sadek Chekroun</b>
          </u>{" "}
          is a graduate of the École Supérieure d’Ingénieurs en Génie Électrique
          de Rouen. He began his career with software publishers for large
          companies (Cincom, Sterling Software, Computer Associates) in
          positions of technical and commercial management and then Marketing.
          He then joined the software division of IBM France and then the EMEA
          headquarters as Marketing Director. Among the pioneers of the
          Internet, he co-founded Skyworld, one of the very first access
          providers in France, sold to CEGETEL before joining Kelkoo as General
          Manager and then VP e-Commerce Europe when it was acquired by Yahoo!.
          He then held general management positions in startups such as Eyeka
          and Wikio/Teads and worked with a Family office in Asia.
          <br />
        </p>
      )}

      <br />
      <br />

      {/* <img src="google-app-store.png" alt="google app store" height="100" />
      <br /> */}
      <br />
    </>
  );
}
