import React from "react";
import "./App.css";

export default function PageBlockchain(props) {
  return (
    <>
      {props.lang == "francais" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          La technologie Blockchain : pour une base de données
          <br /> sécurisée et infalsifiable
        </h2>
      )}
      {props.lang == "english" && (
        <h2
          style={{
            fontFamily: "Helvetica",
            textAlign: "left",
            fontWeight: "bold",
          }}
        >
          Blockchain technology: for a secure and tamper-proof database
        </h2>
      )}

      {/* <div className="small-image-container"> */}
      {/* <img className="small-image-container"
          src="fruits-and-vegetables-732x549.jpg"
          alt="fruits"
          height="100"
          width="20%"
        /> */}
      {/* </div> */}
      {props.lang == "francais" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          {/* <br /> */}
          <br />
          Une blockchain est une base de données dupliquée par plusieurs acteurs
          (les mineurs) qui se chargent de la modifier selon des règles très
          précises qui s'imposent à eux et qui les empêchent de modifier la base
          de façon illicite. Les informations entrées dans la base sont incluses
          dans des blocs chaînés entre eux en utilisant des algorithmes de
          cryptographie avancés. Il est alors impossible de modifier une donnée
          sans rompre cette chaine de blocs et se voir alors refuser l'accès.
          <br />
          <br />
          Traceur utilise la technologie blockchain Ethereum, qui permet
          d'executer un programme ("smart contract") à chaque interaction avec
          la base. Ce programme est public et connu de tous. Il ne peut être
          modifié. Il est alors impossible de modifier une donnée dans la base
          (la référence d'un lot ingrédient par exemple). La confiance des
          industriels et des consommateurs est alors totale.
          <br />
        </p>
      )}
      {props.lang == "english" && (
        <p style={{ fontFamily: "Helvetica", width: "60%" }}>
          {/* <b>
          <u>Comment fonctionne le système "cherche Youki!"?</u>
        </b> */}
          {/* <br /> */}
          <br />
          A blockchain is a database duplicated by several actors (miners) who
          are responsible for modifying it according to very precise rules that
          are imposed on them and that prevent them from modifying the database
          illicitly. The information entered in the database is included in
          blocks chained together using advanced cryptography algorithms. It is
          then impossible to modify data without breaking this chain of blocks
          and then being denied access.
          <br />
          <br />
          Traceur uses Ethereum blockchain technology, which allows a program
          ("smart contract") to be executed at each interaction with the
          database. This program is public and known to all. It cannot be
          modified. It is then impossible to modify data in the database (the
          reference of an ingredient batch for example). The confidence of
          manufacturers and consumers is then total.
          <br />
        </p>
      )}

      <br />
      <br />
      <img src="blockchain.jpg" alt="blockchain" width="50%" />
      {/* <img src="google-app-store.png" alt="google app store" height="100" />
      <br /> */}
      <br />
      <br />
    </>
  );
}
