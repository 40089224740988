import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import Navigation from "./Navigation";

function App() {
  return (
    <>
      {/* <h3 style={{fontFamily: "Georgia, serif", textAlign: "center" }}>
     <strong>CHERCHE YOUKI! - ACCES FOURNISSEURS</strong>
   </h3> */}

      <Navigation />

      {/* <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Oui oui Ici version corporate <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div> */}
    </>
  );
}

export default App;
